import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MessageContainer = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Not Found" />
    <MessageContainer>
      <h1>We're sorry...</h1>
      <p>You are trying to access a page that does not exist.</p>

      <Link to="/">Go Home</Link>
    </MessageContainer>
  </Layout>
)

export default NotFoundPage
